.desktop-carousel .swiper-wrapper {
    align-items: start;
}

.desktop-carousel .swiper-slide {
    border-width: 2px;
    background-color: #98b4801a;
    border-color: #62ba38;
    opacity: 1;
}

.mobile-carousel .swiper-slide {
    width: 80%;
    border-width: 2px;
    background-color: #ffffff;
    border-color: #dfdfdf;
    opacity: 0.5;
    transition: background-color 0.25s linear, border-color 0.25s linear, opacity 0.25s linear;
}

.mobile-carousel .swiper-slide.swiper-slide-active {
    background-color: #98b4801a;
    border-color: #62ba38;
    opacity: 1;
}

.swiper-button-prev,
.swiper-button-next {
    z-index: 100;
    color: #62ba38;
}
